import {TEMPLATE_SELECTED, USER_LOGGED_IN, USER_LOGGED_OUT, USER_UPDATED} from "./ActionTypes";
import {Template, User} from "../types/template";

export function loginUser(user: User) {
  return {user: user, type: USER_LOGGED_IN};
}

export function logoutUser() {
  return {type: USER_LOGGED_OUT};
}

export function selectTemplate(template: Template) {
  return {template: template, type: TEMPLATE_SELECTED};
}

export function updateUser(user: User) {
  return {user: user, type: USER_UPDATED};
}
