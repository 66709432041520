import {Alert, Button, Input, Modal, Select, Spin, Switch} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ShortcutLabel from "../../../../components/shortcut";
import {Template} from "../../../../types/template";

const {TextArea} = Input;
const {Option} = Select;

const Container = styled.div`

`;

const Label = styled.div`
  margin-bottom: 5px;
  color: #666;
`;

const TemplateForm = (props: TemplateFormProps) => {
  const [name, setName] = useState<string>(props.template?.name);
  const [description, setDescription] = useState<string>(props.template?.description);
  const [tags, setTags] = useState<string[]>(props.template?.tags);
  const [isPrivate, setPrivate] = useState<boolean>(props.template?.isPrivate);
  const [isSaving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!props.template) {
      return;
    }

    setName(props.template.name)
    setDescription(props.template.description)
    setTags(props.template.tags)
  }, [props.template]);

  const saveTemplate = () => {
    setSaving(true);
    props.onSaveTemplate(name, description, tags, (typeof isPrivate === 'undefined') ? false : isPrivate);
    window.setTimeout(() => {
      setSaving(false);
    }, 1000);
  };

  const cloneTemplate = () => {
    props.onCloneTemplate();
  };

  const deleteTemplate = () => {
    props.onDeleteTemplate();
  };

  const onPrivateChange = (value: boolean) => {
    setPrivate(!value);
  };

  const options = (tags || []).map((tag: string, index: number) => (
    <Option key={`${tag}-${index}`} value={tag}>{tag}</Option>
  ))

  let tagValues: any = tags;

  return (
    <Container>
      <div>
        <Label>
          Template Name
        </Label>
        <Input placeholder="Enter template name" value={name} onChange={(e) => setName(e.target.value)}/>
      </div>

      <div style={{marginTop: "10px"}}>
        <Label>
          Description
        </Label>
        <TextArea rows={4} placeholder="Describe what it does" value={description}
                  onChange={(e) => setDescription(e.target.value)}/>
      </div>

      <div style={{marginTop: "10px"}}>
        <Label>
          Tags
        </Label>
        <Select value={tagValues} mode="tags" style={{width: '100%'}}
                onChange={(value: string[]) => setTags(value)} tokenSeparators={[',']}>
          {options}
        </Select>
      </div>

      <div style={{marginTop: "20px"}}>
        <div style={{float: "right"}}>
          <Switch onChange={onPrivateChange} checked={!isPrivate}/>
        </div>
        <Label>
          Make Public
        </Label>
      </div>

      <div style={{marginTop: 20}}>
        <Spin spinning={isSaving}>
          <Button type="primary" block onClick={() => saveTemplate()}>
            Save <ShortcutLabel keys="ctrl+s"/>
          </Button>
        </Spin>
      </div>

      <div style={{marginTop: 5}}>
        <Button type="default" block onClick={() => cloneTemplate()}>
          Save As...
        </Button>
      </div>

      <div style={{marginTop: 20}}>
        <Button block onClick={() => props.onCancel()}>
          Done
        </Button>
      </div>

      <div style={{position: "absolute", bottom: 50, width: 220}}>
        <Button block style={{color: "red"}} onClick={() => deleteTemplate()}>
          Delete Template
        </Button>
      </div>
    </Container>
  );
}

export default TemplateForm;

export interface TemplateFormProps {
  template: Template;
  onSaveTemplate: (name: string, description: string, tags: string[], isPrivate: boolean) => void;
  onCloneTemplate: () => void;
  onDeleteTemplate: () => void;
  onCancel: () => void;
}
