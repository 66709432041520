import React, {useState} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import {AutoComplete, Button, Input, Modal, Tag} from "antd";
import ShortcutLabel from "../../shortcut";
import {GlobalHotKeys} from "react-hotkeys";
import {firestore} from "../../../firebase";
import {useHistory} from "react-router-dom";

const {Option} = AutoComplete;

const Container = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  padding-top: 2px;
`;

const SEARCH_KEYS = "ctrl+f";

interface TemplateOption {
  id: string;
  name: string;
  description: string;
  tags: string[];
}

const SearchButton = (props: any) => {
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [options, setOptions] = useState<TemplateOption[]>([]);
  const [value, setValue] = useState<string>();

  const keyMap = {OPEN_FIND_TEMPLATE_MODAL: SEARCH_KEYS};
  const handlers = {
    OPEN_FIND_TEMPLATE_MODAL: () => {
      openFindTemplatesModal();
    }
  };

  const openFindTemplatesModal = () => {
    setIsModalVisible(true);
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const searchOptions = (value: string) => {
    const keywords = value.split(" ").map(val => val.trim()).filter(val => (val !== ""));
    if (keywords.length === 0) {
      setOptions([]);
      return;
    }

    const templatesRef = firestore.collection(`templates`);
    const promises = [];

    if (props.user) {
      promises.push(templatesRef
        .where("searchMetadata", "array-contains-any", keywords)
        .where('isPrivate', '==', true)
        .where('userId', '==', props.user.id)
        .get()
      )
    }
    promises.push(templatesRef
      .where("searchMetadata", "array-contains-any", keywords)
      .where('isPrivate', '==', false)
      .limit(10)
      .get()
    )

    Promise.all(promises).then(results => {
      const opts: TemplateOption[] = [];
      const hash = new Map();
      results.forEach(result => {
        result.forEach(doc => {
          const data = doc.data();
          if (!hash.has(doc.id)) {
            hash.set(doc.id, true);
            opts.push({
              id: doc.id,
              name: data.name || "",
              description: data.description || "",
              tags: data.tags || []
            });
          }
        });
      })
      setOptions(opts);
    }).catch((e) => {
      console.log(e);
      // setError('Failed to load templates')
    });
  }

  const selectOption = (value: string) => {
    if (props.user) {
      const option = options.find(opt => (opt.id === value));
      if (option) {
        firestore
          .collection(`users`)
          .doc(props.user.uid)
          .collection(`recentTemplates`)
          .doc(value)
          .set({
              name: option.name,
              lastViewedAt: new Date()
            }, {merge: true}
          )
          .then(() => {
            setIsModalVisible(false);
            setValue("");

            history.push(`/templates/${value}`);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setIsModalVisible(false);
      setValue("");

      history.push(`/templates/${value}`);
    }
  }

  const autoCompleteOptions: AutocompleteOption[] = options.map((opt) => ({
    value: opt.id,
    label: (
      <div>
        <div style={{float: "right"}}>
          {opt.tags.map((tag: string) => (
            <Tag key={tag} style={{padding: 2, marginRight: 3, fontSize: 12}} color="gold">{tag}</Tag>))}
        </div>

        {opt.name}
        <div style={{fontSize: 10, color: "#888"}}>
          {opt.description}
        </div>
      </div>
    )
  }));

  return (
    <Container>
      <Button type="primary" onClick={openFindTemplatesModal}>Find Templates <ShortcutLabel
        keys={SEARCH_KEYS}/></Button>

      <Modal title="Find Templates"
             visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
             okText="Select" cancelText="Cancel"
             footer={null}
      >
        <AutoComplete autoFocus
                      onChange={setValue}
                      value={value}
                      options={autoCompleteOptions}
                      style={{width: "100%"}}
                      onSelect={selectOption}
                      onSearch={searchOptions}
        >
          <Input.Search size="large" placeholder="Enter keywords to find templates..." enterButton/>
        </AutoComplete>
      </Modal>

      <GlobalHotKeys keyMap={keyMap} handlers={handlers}/>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchButton);

interface AutocompleteOption {
  value: string;
  label: any;
}
