import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Layout = (props: any) => {
  return (
    <Container>
      {props.children}
    </Container>
  );
}

export default Layout;
