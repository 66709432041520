import {Alert, Button, Input, Modal} from 'antd';
import React, {useState} from 'react';
import styled from "styled-components";
import ninjaSitting from "../../../assets/images/ninja-sitting.png";
import ninjaStanding from "../../../assets/images/ninja-standing.png";

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const LeftButton = styled.div`
  float: left;
  margin-top: 5px;
  color: #888;
`;

const LeftButtonLink = styled.div`
  display: inline-block;
  color: #1890ff;
  cursor: pointer;
`;

const SignUpModal = (props: SignUpModalProps) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const handleOk = () => {
    if (props.mode === "sign-in") {
      props.onSignIn(email, password);
    } else {
      props.onSignUp(email, password, passwordConfirmation);
    }
  };

  const handleCancel = () => {
    props.onCancel();
  }

  const switchMode = (mode: string) => {
    props.onModeChange(mode);
  }

  return (
    <Modal
      title={(props.mode === "sign-up") ? "Sign Up To Become A CodeGen Ninja" : "Sign In To CodeGen Ninja"}
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
      footer={
        (props.mode === "sign-up") ? [
          <LeftButton key="sign_in">
            Already a Ninja? <LeftButtonLink onClick={() => switchMode("sign-in")}>Sign In</LeftButtonLink>
          </LeftButton>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Sign Up
          </Button>
        ] : [
          <LeftButton key="sign_up" style={{float: "left", marginTop: 5, color: "#888"}}>
            Not yet a Ninja? <LeftButtonLink onClick={() => switchMode("sign-up")}>Sign Up</LeftButtonLink>
          </LeftButton>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Sign In
          </Button>
        ]}
      okText="Sing Up" cancelText="Cancel"
    >
      <div style={{textAlign: "center"}}>
        {(props.mode === "sign-in") ? (
          <img src={ninjaSitting} alt="Ninja sitting" style={{height: 180}}/>
        ) : (
          <img src={ninjaStanding} alt="Ninja jumping" style={{height: 180}}/>
        )}
      </div>

      {props.error && (
        <Alert message={props.error} type="error" showIcon style={{marginTop: 15}}/>
      )}

      <Label>Email:</Label>
      <Input
        placeholder="Enter email" autoFocus value={email}
        onChange={(event: any) => setEmail(event.target.value)}/>

      <Label>Password:</Label>
      <Input.Password
        placeholder="Enter password" value={password}
        onChange={(event: any) => setPassword(event.target.value)}/>

      {(props.mode === "sign-up") && (
        <>
          <Label>Confirm Password:</Label>
          <Input.Password
            placeholder="Confirm password" value={passwordConfirmation}
            onChange={(event: any) => setPasswordConfirmation(event.target.value)}/>
        </>
      )}
    </Modal>
  );
}

export default SignUpModal;

export interface SignUpModalProps {
  mode: string;
  error: string | undefined;
  visible: boolean;
  onModeChange: (mode: string) => void
  onCancel: () => void
  onSignUp: (email: string, password: string, passwordConfirmation: string) => void
  onSignIn: (email: string, password: string) => void
}
