const Handlebars = require("handlebars");

const HandlebarsEngine = {
  generate: (template, tokens) => {
    return new Promise((resolve, reject) => {
      try {
        const compiledTemplate = Handlebars.compile(template);
        const output = compiledTemplate(tokens);
        resolve(output);
      } catch (error) {
        reject(error);
      }
    });
  }
};

export default HandlebarsEngine;