import React from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ViewTemplate from './pages/templates/view';
import EditTemplate from './pages/templates/edit';
import HomePage from "./pages/home";
import LinkPage from "./pages/links";
import EditUserProfile from "./pages/users";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path='/' exact={true} component={HomePage}/>
        <Route path='/l/:id' exact={true} component={LinkPage}/>
        <Route path='/users/:id/edit' exact={true} component={EditUserProfile}/>
        <Route path='/templates/new' exact={true} component={EditTemplate}/>
        <Route path='/templates/:id/edit' component={EditTemplate}/>
        <Route path='/templates/:id/:linkId?' exact={true} component={ViewTemplate}/>
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);

