import React, {useState} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import {Button, Input, Modal, Select, Divider, Alert} from "antd";
import ShortcutLabel from "../../shortcut";
import {GlobalHotKeys} from "react-hotkeys";
import {useHistory} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {firestore} from "../../../firebase";

const {Option} = Select;
const {TextArea} = Input;

const Container = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  padding-top: 2px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  color: #666;
`;

const NEW_TEMPLATE_KEYS = "ctrl+n";

const inputLanguageOptions = ["liquid"];

const outputLanguageOptions = [
  "text", "html", "kotlin", "ruby", "java", "javascript", "php", "json", "csharp", "objectivec", "golang", "rust", "swift", "typescript", "python", "c_cpp"
].sort();

const Search = (props: any) => {
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [outputContentType, setOutputContentType] = useState<string>("html");
  const [engine, setEngine] = useState<string>("liquid");

  const keyMap = {OPEN_NEW_TEMPLATE_MODAL: NEW_TEMPLATE_KEYS};
  const handlers = {
    OPEN_NEW_TEMPLATE_MODAL: () => {
      openNewTemplateModal();
    }
  };

  const openNewTemplateModal = () => {
    setIsModalVisible(true);
  }

  const handleOk = () => {
    if (name === "") {
      setError("Name must be specified");
      return
    }

    const templateId = uuidv4();

    firestore.collection(`templates`).doc(templateId).set({
      name: name,
      description: description,
      engine: engine || "liquid",
      input: "json",
      output: outputContentType || "html",
      sampleTokens: JSON.stringify({
        user: props.user.email,
        engine: outputContentType
      }, null, 2),
      content: "Hello {{user}}, \nchange this content to start generating code using {{engine}} engine.\nUse the Run button to execute your template and view the output.",
      isPrivate: true,
      userId: props.user.id,
      tags: tags
    }).then(result => {
      setIsModalVisible(false);
      history.push(`/templates/${templateId}/edit`);
    }).catch(error => {
      console.log(error);
      setError('failed to create a new template')
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!props.user) {
    return (<></>)
  }

  const options = (tags || []).map((tag: string, index: number) => (
    <Option key={`${tag}-${index}`} value={tag}>{tag}</Option>
  ))

  let tagValues: any = tags;

  return (
    <Container>
      <Button type="primary" onClick={openNewTemplateModal}>
        Create New Template <ShortcutLabel keys={NEW_TEMPLATE_KEYS}/>
      </Button>

      <Modal title="Create A New Template"
             visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
             okText="Create Template" cancelText="Cancel"
      >
        {error && (
          <Alert message={error} type="error" showIcon style={{marginBottom: 15}}/>
        )}

        <div>
          <Label>
            Template Name:
          </Label>
          <Input autoFocus={true} placeholder="Enter template name" value={name}
                 onChange={(e) => setName(e.target.value)}/>
        </div>

        <div style={{marginTop: 15}}>
          <Label>
            Description:
          </Label>
          <TextArea rows={4} placeholder="Describe what it generates" value={description}
                    onChange={(e) => setDescription(e.target.value)}/>
        </div>

        <div style={{marginTop: 15}}>
          <Label>
            Choose Template Engine:
          </Label>
          <Select defaultValue={engine}
                  style={{width: '100%'}}
                  onChange={(value: string) => setEngine(value)}
          >
            {inputLanguageOptions.map((opt) => (
              <Option key={opt} value={opt}>{opt}</Option>
            ))}
          </Select>
          <div style={{fontSize: 10, color: "#888", marginTop: 5, fontStyle: "italic"}}>
            You need at least an orange belt for <strong>Handlebars</strong> and <strong>EJS</strong>.
          </div>
        </div>

        <div style={{marginTop: 15}}>
          <Label>
            Select Output Content Type:
          </Label>
          <Select defaultValue={outputContentType}
                  style={{width: '100%'}}
                  onChange={(value: string) => setOutputContentType(value)}
          >
            {outputLanguageOptions.map((opt) => (
              <Option key={opt} value={opt}>{opt}</Option>
            ))}
          </Select>
        </div>

        <div style={{marginTop: 15}}>
          <Label>
            Add Tags:
          </Label>
          <Select defaultValue={tagValues} mode="tags" style={{width: '100%'}}
                  onChange={(value: string[]) => setTags(value)}
                  tokenSeparators={[',']}>
            {options}
          </Select>
          <div style={{fontSize: 10, color: "#888", marginTop: 5, fontStyle: "italic"}}>
            This is how others can find your templates.
          </div>
        </div>

        <Divider orientation="left" />

        <div style={{marginTop: "10px", color: "#888", fontStyle: "italic"}}>
          This template will be created as private and only you can seen it.
          You can choose to publish it and share it with other ninjas.
        </div>
      </Modal>

      <GlobalHotKeys keyMap={keyMap} handlers={handlers}/>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

interface AutocompleteOption {
  value: string;
  label: any;
}
