import React from 'react';
import { connect } from "react-redux";
import styled from "styled-components";
import {version} from "../../../../package.json";

const Container = styled.div`
  flex: 0 0 auto;
  font-size: 12px;
  padding: 12px 20px;
  border-top: 1px solid #e5e5e5;
  height: 40px;
`;

const Index = (props: any) => {
  return (
    <Container>
      <div style={{float: "right"}}>
        Made with <span style={{color: "red"}}>❤</span> by lazy developers for lazy developers. Powered by Firebase.
      </div>
      Copyright &copy; 2021 CodeGen Ninja <div style={{color: "#ccc", display: "inline-block", marginLeft: 10, fontSize: 10}}>v{version}</div>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  context: state.context
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
