import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router";
import {TemplateLink} from "../../types/template";
import LinkAPI from "../../firebase/api/link";
import ninjaSitting from "../../assets/images/ninja-sitting.png";

const LinkPage = (props: any) => {
  const [link, setLink] = useState<TemplateLink | null>();

  useEffect(() => {
    if (!link) {
      LinkAPI.byId(props.match.params.id).then(link => {
        setLink(link);
      }).catch(ex => {
        console.error("Failed to load tokens from link", ex);
      });
    }
  }, [link, props.match.params.id]);

  if (link) {
    const url = `/templates/${link.templateId}/${link.id}`;

    return (
      <Redirect to={url}/>
    );
  }

  return (
    <div style={{textAlign: "center", paddingTop: 50, fontFamily: "Finger Paint"}}>
      <div>
        <div style={{color: "#333", fontSize: 40, marginBottom: 30}}>CodeGen Ninja</div>
      </div>
      <div style={{marginBottom: 50}}>
        <img src={ninjaSitting} alt="CodeGen Ninja" style={{width: 250}}/>
      </div>
      Loading...
    </div>
  );
}
export default LinkPage;
