import React from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import Title from "./Title";
import UserMenu from "./UserMenu";
import SignInButton from "./SignUpButtons";
import SearchButton from "./SearchButton";
import NewTemplateButton from "./NewTemplateButton";
import {loginUser, logoutUser} from "../../../redux/ActionCreators";

const Container = styled.div`
  flex: 0 0 auto;
  //padding: 0 10px;
  background-color: #fcfcfc;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px;
  height: 50px;
`;

const Link = styled.div`
  display: inline-block;
  margin-right: 100px;
  color: #e57600;
`;

const Index = (props: any) => {
  return (
    <Container>
      <div style={{float: "right", display: "inline-block"}}>
        {!props.user && (
          <Link>
            Become a <strong>CodeGen Ninja</strong> and start creating your own code templates!
          </Link>
        )}

        {false && (
          <SearchButton/>
        )}

        <NewTemplateButton/>
        <div style={{marginLeft: 10, display: "inline-block"}}>
          <SignInButton/>
        </div>
      </div>

      <Title/>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  loginUser, logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
