const Mustache = require('mustache');

const MustacheEngine = {
  generate: (template, tokens) => {
    return new Promise((resolve, reject) => {
      try {
        const output = Mustache.render(template, tokens);
        resolve(output);
      } catch (error) {
        reject(error);
      }
    });
  }
};

export default MustacheEngine;