import React from 'react';
import styled from "styled-components";

const Key = styled.kbd`
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    color: #333;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
    display: inline-block;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
`;

const Container = styled.div`
  display: inline-block;
  font-family: verdana;
  font-size: 9px;
  margin-left: 10px;
  white-space: nowrap;
  vertical-align: middle;
`;

const ShortcutLabel = (props: ShortcutLabelProps) => {

  if (true) {
    return (<></>);
  }

  const parts = props.keys.split("+");

  return (
    <Container>
      {parts.map((part, index) => (
        <div style={{display: "inline-block"}} key={index}><Key>{part}</Key>{(index < parts.length-1) ? "+" : ""}</div>
      ))}
    </Container>
  );
}

export default ShortcutLabel;

export interface ShortcutLabelProps {
  keys: string
}
