import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './redux/Store';
import 'antd/dist/antd.css';
import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

Honeybadger.configure({
  apiKey: '59527eda',
  environment: 'production'
})

ReactDOM.render(
  <ErrorBoundary honeybadger={Honeybadger}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
