import JstEngine from "./jst/JstEngine";
import LiquidEngine from "./liquid/LiquidEngine";
import MustacheEngine from "./mustache/MustacheEngine";
import HandlebarsEngine from "./handlebars/HandlebarsEngine";
import EjsEngine from "./ejs/EjsEngine";

const Generator = {

  generate: function (engine, content, tokens) {
    return new Promise((resolve, reject) => {
      try {
        if (engine === "liquid") {
          LiquidEngine.generate(content, tokens).then((result) => {
            resolve(result);
          }).catch(reject);
        } else if (engine === "mustache") {
          MustacheEngine.generate(content, tokens).then((result) => {
            resolve(result);
          }).catch(reject);
        } else if (engine === "jst") {
          JstEngine.generate(content, tokens).then((result) => {
            resolve(result);
          }).catch(reject);
        } else if (engine === "handlebars") {
          HandlebarsEngine.generate(content, tokens).then((result) => {
            resolve(result);
          }).catch(reject);
        } else if (engine === "ejs") {
          EjsEngine.generate(content, tokens).then((result) => {
            resolve(result);
          }).catch(reject);
        } else {
          resolve("");
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default Generator;
