import React from 'react';
import styled from "styled-components";
import AceEditor from "react-ace";
import {Select, Tag, Button} from 'antd';

//input modes
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-sql";

// template modes
import "ace-builds/src-noconflict/mode-ejs";
import "ace-builds/src-noconflict/mode-liquid";
import "ace-builds/src-noconflict/mode-handlebars";

// output modes
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-kotlin";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-objectivec";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-rust";
import "ace-builds/src-noconflict/mode-swift";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";

// themes
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-clouds";
import "ace-builds/src-noconflict/theme-crimson_editor";
import "ace-builds/src-noconflict/theme-dawn";
import "ace-builds/src-noconflict/theme-dreamweaver";
import "ace-builds/src-noconflict/theme-eclipse";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-iplastic";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-kuroir";
import "ace-builds/src-noconflict/theme-katzenmilch";
import "ace-builds/src-noconflict/theme-sqlserver";

import "ace-builds/src-noconflict/theme-ambiance";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-clouds_midnight";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/theme-cobalt";
import "ace-builds/src-noconflict/theme-gruvbox";
import "ace-builds/src-noconflict/theme-gob";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-kr_theme";
import "ace-builds/src-noconflict/theme-merbivore";
import "ace-builds/src-noconflict/theme-merbivore_soft";
import "ace-builds/src-noconflict/theme-mono_industrial";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-nord_dark";
import "ace-builds/src-noconflict/theme-pastel_on_dark";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import "ace-builds/src-noconflict/theme-tomorrow_night_blue";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/theme-vibrant_ink";
import {ICommand, ICommandBindKey} from "react-ace/src/types";

const {Option, OptGroup} = Select;

// const templateOptions = [
//   "ejs", "liquid", "handlebars"
// ];
//
// const tokensOptions = [
//   "json"
// ];
//
// const outputOptions = [
//   "html", "kotlin", "ruby",
//   "java", "javascript", "php",
//   "text", "csharp", "objectivec",
//   "golang", "rust", "swift",
//   "typescript", "python", "c_cpp"
// ];

const themeOptions = [{
  group: "bright", themes: [
    "chrome", "clouds", "crimson_editor", "dawn",
    "dreamweaver", "eclipse", "github", "iplastic",
    "solarized_light", "textmate", "tomorrow",
    "xcode", "kuroir", "katzenmilch", "sqlserver"
  ]
}, {
  group: "dark", themes: [
    "ambiance", "chaos", "clouds_midnight", "dracula",
    "cobalt", "gruvbox", "gob", "idle_fingers", "kr_theme",
    "merbivore", "merbivore_soft", "mono_industrial",
    "monokai", "nord_dark", "pastel_on_dark", "solarized_dark",
    "terminal", "tomorrow_night", "tomorrow_night_blue",
    "tomorrow_night_bright", "tomorrow_night_eighties",
    "twilight", "vibrant_ink"
  ]
}];

const Container = styled.div`
`;

const Title = styled.div`
  padding: 3px;
  padding-left: 10px;
  background: #eee;
`;

const EditorContainer = styled.div`
  width: 100%;
`;

const titleHeight = 32;

const Editor = (props: EditorProperties) => {
  const onContentChange = (value: string) => {
    if (props.onContentChange) {
      props.onContentChange(value);
    }
  };

  const onModeChange = (value: string) => {
    if (props.onModeChange) {
      props.onModeChange(value);
    }
  };

  const onThemeChange = (value: string) => {
    if (props.onThemeChange) {
      props.onThemeChange(value);
    }
  };

  const tidyJSON = () => {
    const value = props.content || "{}";
    try {
      const parsed = JSON.parse(value);
      onContentChange(JSON.stringify(parsed, null, 2));
    } catch (e) {
      console.error("Failed to parse content");
    }
  };

  const openDocumentation = () => {
    switch (props.mode) {
      case "liquid":
        window.open("https://liquidjs.com/tags/overview.html");
        return
      case "ejs":
        window.open("https://ejs.co/#docs");
        return
      case "handlebars":
        window.open("https://handlebarsjs.com/guide/#what-is-handlebars");
        return
    }
  };

  return (
    <Container style={{width: props.width, height: props.height}}>
      <Title style={{width: props.width, height: titleHeight}}>
        <div style={{float: "right", marginRight: 5}}>
          {props.titleContent}

          {(props.mode === "liquid" || props.mode === "ejs" || props.mode === "handlebars") ? (
            <Button size="small" style={{marginRight: 5}} onClick={openDocumentation}>View Documentation</Button>
          ) : ""}

          {(props.mode === "json") ? (
            <Button size="small" style={{marginRight: 5}} onClick={tidyJSON}>Tidy</Button>
          ) : ""}

          {props.modeOptions && (
            <Select value={props.mode || "liquid"} style={{width: 100, marginRight: 5}} onChange={onModeChange} size="small">
              {props.modeOptions.map((opt) => (
                <Option key={opt} value={opt}>{opt}</Option>
              ))}
            </Select>
          )}

          {!props.modeOptions && (
            <>
              <div style={{display: "inline-block"}}>
                <Tag color="blue">{props.mode}</Tag>
              </div>
            </>
          )}

          {props.allowThemes && (
            <Select defaultValue={props.theme || "tomorrow"} style={{width: 200}} onChange={onThemeChange} size="small">
              {themeOptions.map((group: { group: string, themes: string[] }) => (
                <OptGroup label={group.group}>
                  {group.themes.map((label: string) => (
                    <Option value={label}>{label}</Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          )}
        </div>
        {props.title}
      </Title>
      <EditorContainer>
        <AceEditor
          style={{height: props.height - titleHeight, width: props.width}}
          mode={props.mode || "liquid"}
          theme={props.theme || "tomorrow"}
          onChange={onContentChange}
          value={props.content || ""}
          editorProps={{$blockScrolling: true}}
          commands={props.commands}
          setOptions={{
            useWorker: false,
            readOnly: props.readOnly,
            tabSize: 2,
            useSoftTabs: true,
            highlightActiveLine: !props.disableActiveLineHighlight
          }}
        />
      </EditorContainer>
    </Container>
  );
}

export default Editor;

export interface EditorProperties {
  title: string;
  titleContent?: any;
  mode: string | null;
  theme: string | null;
  content: string | null;
  modeOptions?: string[];
  allowThemes?: boolean;
  width: number;
  height: number;
  readOnly: boolean;
  commands?: ICommand[],
  disableActiveLineHighlight?: boolean,
  onContentChange?: (content: string) => void;
  onModeChange?: (content: string) => void;
  onThemeChange?: (content: string) => void;
}
