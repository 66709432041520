import {Button} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import ninjaStanding from "../../../assets/images/ninja-standing.png";
import ninjaSitting from "../../../assets/images/ninja-sitting.png";
import {auth} from "../../../firebase";
import {loginUser, logoutUser} from "../../../redux/ActionCreators";
import SignUpModal from "./SignUpModal";
import UserMenu from "./UserMenu";
import UserAPI from "../../../firebase/api/user";

const Container = styled.div`
  display: inline-block;
  font-size: 14px;
  padding-top: 2px;
  margin-right: 10px;
`;

const SignUpButtons = (props: any) => {
  const [initialized, setInitialized] = useState<boolean>();
  const [error, setError] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(props.mode);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      console.log("Initializing authentication");

      const remove = auth.onAuthStateChanged(authUser => {
        console.log("User authenticated");

        if (authUser) {
          UserAPI.byId(authUser.uid).then((user) => {
            if (user) {
              props.loginUser(user);
            } else {
              setError("User not found");
            }
          }).catch(error => {
            console.log(error);
            setError(error.message)
          });
        } else {
          props.logoutUser();
        }
      });

      return () => {
        remove();
      }
    }
  }, [initialized, props, props.user]);

  const showModal = (mode: string) => {
    setMode(mode);
    setIsModalVisible(true);
  };

  const handleSignIn = (email: string, password: string) => {
    auth.signInWithEmailAndPassword(email, password).then(() => {
      setInitialized(false);
      setIsModalVisible(false);
    }).catch(error => {
      console.error(error);
      setError(error.message);
    });
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      setInitialized(false);
    }).catch(error => {
      console.error(error);
      setError(error.message);
    });
  };

  const handleSignUp = (email: string, password: string, passwordConfirmation: string) => {
    if (password === "") {
      setError("Password must be provided");
      return
    }

    if (passwordConfirmation !== password) {
      setError("Passwords don't match");
      return
    }

    auth.createUserWithEmailAndPassword(email, password).then((userCreds) => {
      const user = userCreds.user

      if (user) {
        UserAPI.create({
          id: user.uid,
          email: user.email
        }).then(() => {
          setIsModalVisible(false);
          setInitialized(false);
        }).catch(error => {
          console.log(error);
          setError(error.message)
        });
      }
    }).catch(error => {
      console.error(error);
      setError(error.message);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleModeChange = (mode: string) => {
    setMode(mode);
  };

  return (
    <Container>
      {props.user ? (
        <UserMenu onSignOut={handleSignOut}/>
      ) : (
        <>
          <Button type="primary" onClick={() => showModal("sign-up")} style={{marginRight: 10}}
                  icon={<img src={ninjaStanding} alt="Ninja" style={{height: 20, marginRight: 10}}/>}>
            Become A Ninja
          </Button>
          <Button onClick={() => showModal("sign-in")}
                  icon={<img src={ninjaSitting} alt="Ninja star" style={{height: 20, marginRight: 10}}/>}>
            Sign In
          </Button>
          <SignUpModal
            mode={mode}
            visible={isModalVisible}
            error={error || ""}
            onModeChange={handleModeChange}
            onCancel={handleCancel}
            onSignIn={handleSignIn}
            onSignUp={handleSignUp}
          />
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  loginUser, logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpButtons);
