import 'leaflet/dist/leaflet.css';
import React, {useState} from 'react';
import {connect} from "react-redux";
import 'react-splitter-layout/lib/index.css';
import styled from "styled-components";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import Layout from "../../components/layout/Layout";
import {Menu} from "antd";
import Navigation from "../../components/layout/navbar";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import UserAPI from "../../firebase/api/user";
import {updateUser} from "../../redux/ActionCreators";
import {Redirect} from "react-router";
import {auth} from "../../firebase";
import BasicInfoForm from "./BasicInfoForm";

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const Body = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  padding-top: 20px;
`;

const UserPage = (props: any) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const handleClick = (e: any) => {
    console.log('click ', e.key);
  };

  const onFinish = (values: any) => {
    setIsUpdating(true);

    auth.currentUser?.updateEmail(values.email).then(() => {
      UserAPI.update({
        id: props.user.id,
        email: values.email,
        nickname: values.nickname
      }).then(flag => {
        setIsUpdating(false);

        if (flag) {
          props.updateUser({
            ...props.user,
            ...{
              email: values.email,
              nickname: values.nickname
            }
          })
        }
      }).catch(ex => {
        setIsUpdating(false);
        setError(ex);
      });
    }).catch(ex => {
      setIsUpdating(false);
      setError(ex);
    });
  };

  if (!props.user) {
    return (
      <Redirect to="/"/>
    )
  }

  const changePassword = (password: string) => {
    auth.signInWithEmailAndPassword(props.user.email, password).then(() => {

    }).catch(error => {
      console.error(error);
    });
  };

  return (
    <Layout>
      <Header/>
      <Content>
        <Navigation>
          <div style={{fontSize: 16, padding: 10, fontWeight: 'bold'}}>Profile Settings</div>
          <Menu
            onClick={handleClick}
            style={{width: 229, backgroundColor: "#fcfcfc"}}
            defaultSelectedKeys={['basic_info']}
            defaultOpenKeys={['profile_settings']}
            mode="inline"
          >
            <Menu.Item key="basic_info" icon={<UserOutlined/>}>
              Basic Info
            </Menu.Item>
            {/*<Menu.Item key="password" icon={<LockOutlined/>}>*/}
            {/*  Password*/}
            {/*</Menu.Item>*/}
          </Menu>
        </Navigation>
        <Body>
          <BasicInfoForm
            initialValues={{email: props.user.email, nickname: props.user.nickname}}
            onFinish={onFinish}
            isUpdating={isUpdating}
            error={error}
          />
        </Body>
      </Content>
      <Footer/>
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
