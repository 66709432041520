import React, {useState} from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom'
import {selectTemplate} from "../../../../redux/ActionCreators";
import TemplateSelector from "./TemplateSelector";

import {Button, Input, Modal, Skeleton, Tag} from 'antd';
import {Template, User} from "../../../../types/template";
import {v4 as uuidv4} from "uuid";
import {firestore} from "../../../../firebase";

const Container = styled.div`
  position: absolute;
  top: 100px;
  width: 300px;
  right: 15px;
  z-index: 999;
  background: #f1f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
`;

const Label = styled.div`
  margin-bottom: 5px;
  color: #666;
`;

const isUserTemplate = (template: Template | null, user: User) => {
  if (!template || !user) {
    return false
  }

  return (template?.id !== "default" && template?.userId === user.id)
}

const DEFAULT_COPY_URL_LABEL = "Copy Link";
const DEFAULT_COPY_CODE_LABEL = "Copy Code To Clipboard";

const TemplateOptions = (props: TemplateOptionsProps) => {
  const history = useHistory();
  const [copyUrlToClipboardButtonLabel, setCopyUrlToClipboardButtonLabel] = useState<string>(DEFAULT_COPY_URL_LABEL)
  const [copyCodeToClipboardButtonLabel, setCopyCodeToClipboardButtonLabel] = useState<string>(DEFAULT_COPY_CODE_LABEL)
  const [isCloneModalVisible, setCloneModalVisible] = useState(false);
  const [clonedTemplateName, setClonedTemplateName] = useState("");

  const onCopyUrlToClipboard = () => {
    props.onCopyToClipboard("url");
    setCopyUrlToClipboardButtonLabel("Copied!");
    window.setTimeout(() => {
      setCopyUrlToClipboardButtonLabel(DEFAULT_COPY_URL_LABEL);
    }, 1000);
  }

  const onCopyCodeToClipboard = () => {
    props.onCopyToClipboard("content");
    setCopyCodeToClipboardButtonLabel("Copied!");
    window.setTimeout(() => {
      setCopyCodeToClipboardButtonLabel(DEFAULT_COPY_CODE_LABEL);
    }, 1000);
  }

  const onDownloadFile = () => {
    props.onDownloadFile();
  }

  const cloneAsNewTemplate = () => {
    if (props.template) {
      const templateId = uuidv4();

      firestore.collection(`templates`).doc(templateId).set({
        name: clonedTemplateName,
        description: props.template.description,
        engine: props.template.engine,
        input: props.template.inputMode,
        output: props.template.outputMode,
        sampleTokens: props.template.sampleTokens,
        content: props.template.content,
        isPrivate: true,
        userId: props.user.id,
        tags: props.template.tags
      }).then(() => {
        history.push(`/templates/${templateId}/edit`);
      }).catch(error => {
        console.log(error);
        // setError('failed to create a new template')
      });
    }
  }

  const openCloneTemplateModal = () => {
    setClonedTemplateName(props.template?.name || "");
    setCloneModalVisible(true);
  }

  const isOwnerTemplate = isUserTemplate(props.template, props.user);

  return (
    <Container>
      {!props.template && (
        <Skeleton active/>
      )}

      {props.template && (
        <>
          {isOwnerTemplate && (
            <TemplateSelector template={props.template} onTemplateSelected={props.onTemplateSelected}/>
          )}

          <div style={{borderBottom: "1px solid #ddd", paddingBottom: 10, marginBottom: 10}}>
            {!isOwnerTemplate && (
              <div style={{padding: 5, color: "#888", fontSize: 14, fontWeight: "bold"}}>
                {props.template?.name}
              </div>
            )}

            {props.template.description && (
              <div style={{padding: 5, color: "#888", fontSize: 12}}>
                {props.template.description}
              </div>
            )}

            {props.template.tags && (
              <div style={{padding: 5}}>
                {props.template.tags.map(
                  (tag: string) => (
                    <Tag key={tag} style={{padding: 2, marginRight: 3, fontSize: 12}} color="gold">{tag}</Tag>)
                )}
              </div>
            )}
          </div>

          {!isOwnerTemplate && props.user && (
            <div style={{borderBottom: "1px solid #ddd", paddingBottom: 20, marginBottom: 0}}>
              <TemplateSelector template={props.template} onTemplateSelected={props.onTemplateSelected}/>
            </div>
          )}

          <div style={{marginTop: 10}}>
            <Button block onClick={onCopyCodeToClipboard}>{copyCodeToClipboardButtonLabel}</Button>
            <Button block onClick={onDownloadFile}>Download As File</Button>

            <Button style={{marginTop: 10}} block onClick={onCopyUrlToClipboard}>{copyUrlToClipboardButtonLabel}</Button>
          </div>

          {props.user && (
            <div>
              <div style={{marginTop: 10}}>
                {(props.template.userId === props.user.id) ? (
                  <Button block onClick={() => {
                    history.push(`/templates/${props.template?.id}/edit`)
                  }}>Modify This Template</Button>
                ) : (
                  <Button block onClick={openCloneTemplateModal}>Clone To Make Changes</Button>
                )}
              </div>

              <Modal title="Clone Template To Make Changes" visible={isCloneModalVisible} onOk={cloneAsNewTemplate}
                     okText={"Clone Template"} onCancel={() => setCloneModalVisible(false)}>
                <div>
                  <Label>
                    What would you like to call the new template?
                  </Label>
                  <Input autoFocus={true} placeholder="Enter template name" value={clonedTemplateName}
                         onChange={(e) => setClonedTemplateName(e.target.value)}/>
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  selectTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateOptions);

export interface TemplateOptionsProps {
  template: Template | null;
  user: any;
  onTemplateSelected: (templateId: string) => void;
  onCopyToClipboard: (mode: string) => void;
  onDownloadFile: () => void;
}
