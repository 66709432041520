import {firestore} from "../index";
import {User} from "../../types/template";

const collectionName = "users";

const getCollection = () => {
  return firestore.collection(collectionName);
}

const UserAPI = {
  byId: (userId: string) => {
    return new Promise<User | null>((resolve, reject) => {
      getCollection().doc(userId).get()
        .then(template => {
          const data = template.exists ? template.data() : null;
          if (data) {
            resolve({
              id: userId,
              email: data.email,
              belt: data.belt,
              nickname: data.nickname,
              createdAt: data.createdAt
            })
          } else {
            resolve(null)
          }
        })
        .catch(e => {
          reject(e)
        });
    })
  },

  create: (params: UserCreateRequest) => {
    return new Promise<User>((resolve, reject) => {
      const createdAt = new Date();
      const belt = "white";

      getCollection().doc(params.id).set({
        email: params.email,
        belt: belt,
        createdAt: createdAt
      }).then(() => {
        resolve({
          id: params.id,
          email: params.email,
          belt: belt,
          createdAt: createdAt
        })
      }).catch(error => {
        reject(error);
      });
    });
  },

  update: (params: UserUpdateRequest) => {
    return new Promise<boolean>((resolve, reject) => {
      getCollection().doc(params.id).set({
        email: params.email,
        nickname: params.nickname
      }, {merge: true}).then(() => {
        resolve(true)
      }).catch(error => {
        reject(error);
      });
    });
  }
}

export default UserAPI;

export interface UserCreateRequest {
  id: string;
  email: string | null;
}

export interface UserUpdateRequest {
  id: string;
  email: string;
  nickname: string;
}
