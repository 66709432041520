import { USER_LOGGED_IN, USER_LOGGED_OUT, TEMPLATE_SELECTED, STORE_ROOT, USER_UPDATED } from "./ActionTypes";
import StorageManager from './StorageManager';

const initialState = StorageManager.load(STORE_ROOT) || {
  auth: {
    user: null
  },
  context: {
    template: null
  }
};

export default function reduce(state = initialState, action: any) {
  // console.log("action", action);

  switch (action.type) {
    case USER_LOGGED_IN:
      const loginAuth = { ...state.auth };
      loginAuth.user = action.user;
      return {
        ...state,
        auth: loginAuth
      };

    case USER_LOGGED_OUT:
      const logoutAuth = { ...state.auth };
      logoutAuth.user = null;
      return {
        ...state,
        auth: logoutAuth
      };

    case USER_UPDATED:
      const userAuth = { ...state.auth };
      userAuth.user = action.user;
      return {
        ...state,
        auth: userAuth
      };

    case TEMPLATE_SELECTED:
      const templateContext = { ...state.context };
      templateContext.template = { ...action.template };
      return {
        ...state,
        context: templateContext
      };

    default:
      return state;
  }
}
