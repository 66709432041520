import React from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import styled from "styled-components";
import ninjaKicking from "../../../assets/images/ninja-kicking.png";

const Container = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-left: 10px;
`;

const getRandomProverb = () => {
  const options = ["大きな力には大きな責任が伴います", "悪い職人はいつも自分の道具を非難します", "手にした鳥は茂みの中で2羽の価値があります",
    "行動は言葉よりも雄弁です", "外見は欺くことができます", "人生で最高のものは無料です", "あなたを養う手を噛まないでください", "本を表紙で判断しないでください", "壊れていない場合は直さないでください"
  ];

  return options[Math.floor(Math.random() * options.length)];
}

const Title = (props: any) => {
  return (
    <Container>
      <img src={ninjaKicking} alt="Ninja" style={{width: "30px", marginRight: "10px"}}/>
      <Link to="/" style={{color: "#333", fontFamily: "Finger Paint", fontSize: 20}}>CodeGen Ninja</Link>
      <span style={{paddingLeft: 10, fontSize: 12, color: "#888"}}>{getRandomProverb()}</span>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Title);
