const ejs = require('ejs');

const EjsEngine = {
  generate: (template, tokens) => {
    return new Promise((resolve, reject) => {
      try {
        const content = ejs.render(template, tokens);
        resolve(content);
      } catch (error) {
        reject(error);
      }
    });
  }
};

export default EjsEngine;
