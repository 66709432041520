import 'leaflet/dist/leaflet.css';
import React from 'react';
import 'react-splitter-layout/lib/index.css';
import {Alert, Button, Form, Input, Spin} from "antd";
import Gravatar from 'react-awesome-gravatar';

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 8},
};

const BasicInfoForm = (props: BasicInfoFormProps) => {
  const [form] = Form.useForm();

  return (
    <Form
      {...layout}
      form={form}
      name="basics"
      initialValues={props.initialValues}
      onFinish={props.onFinish}
      size="middle"
    >
      <Form.Item wrapperCol={{...layout.wrapperCol, offset: 4}}>
        <h1>Basic Information</h1>
        {props.error && (
          <Alert message={props.error} type="error" showIcon style={{marginTop: 15}}/>
        )}
      </Form.Item>
      <Form.Item label="Mugshot">
        <Gravatar email={props.initialValues.email} options={{size: 100}}>
          {url => (
            <img src={url} alt="Mugshot" style={{marginRight: 7, verticalAlign: "bottom", borderRadius: 10}}/>)}
        </Gravatar>
        <span style={{color: "#888"}}>
                Visit <a href="https://en.gravatar.com/">Gravatar</a> to update.
              </span>
      </Form.Item>
      <Form.Item name="nickname" label="Ninja Nickname" rules={[
        {
          required: true,
          message: 'Nickname is required.'
        }
      ]}>
        <Input placeholder="Enter a nickname that will be visible to others"/>
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[
        {required: true},
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        }
      ]}>
        <Input placeholder="Enter your email"/>
      </Form.Item>
      <Form.Item wrapperCol={{...layout.wrapperCol, offset: 4}}>
        <Spin spinning={props.isUpdating}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Spin>
      </Form.Item>
    </Form>
  );
}

export default BasicInfoForm;

export interface BasicInfoFormProps {
  error: string | null | undefined;
  initialValues: any;
  isUpdating: boolean;
  onFinish: (values: any) => void;
}
