import {Button} from 'antd';
import React from 'react';
import Editor from "../../../../components/editor/Editor";

const InputEditor = (props: InputEditorProps) => {
  const importTokens = () => {

  };

  return (
    <Editor
      key="tokens_editor"
      title="Sample Tokens"
      titleContent={false ? (
        <div style={{display: "inline-block", marginRight: 20}}>
          <Button size="small"
                  onClick={() => importTokens()}
          >
            Import From URL
          </Button>
        </div>
      ) : ""}
      mode={props.mode || "json"}
      theme="tomorrow_night"
      width={props.size.width}
      height={props.size.height}
      content={props.content}
      readOnly={false}
      commands={[
        {
          name: "Save script",
          bindKey: {win: "ctrl+s", mac: "command+s"},
          exec: (editor) => props.onSaveContent(editor.getValue())
        }
      ]}
      onContentChange={(content: string) => {
        props.onContentChanged(content);
      }}
    />
  );
}

export default InputEditor;

export interface InputEditorProps {
  mode: string | null;
  size: { width: number, height: number };
  content: string;
  onContentChanged: (content: string) => void;
  onSaveContent: (content: string) => void;
}
