import {Input, Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const Label = styled.div`
  margin-bottom: 5px;
  color: #666;
`;

const SaveAsNewTemplateModal = (props: SaveAsNewTemplateModalProps) => {
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (props.name !== name) {
      setName(props.name);
    }
  }, [props.name])

  return (
    <Modal title="Save As a New Template"
           visible={props.visible}
           onOk={() => props.onOk(name)}
           onCancel={() => props.onCancel()}
           okText="Save As New Template" cancelText="Cancel"
    >
      <Label>
        Template Name:
      </Label>
      <Input
        autoFocus={true}
        placeholder="Enter template name"
        value={name}
        onChange={(e) => setName(e.target.value)}/>
    </Modal>
  );
}

export default SaveAsNewTemplateModal;

export interface SaveAsNewTemplateModalProps {
  visible: boolean;
  name: string;
  onOk: (newName: string) => void;
  onCancel: () => void;
}
