import 'leaflet/dist/leaflet.css';
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import styled from "styled-components";
import Editor from "../../../components/editor/Editor";
import Footer from "../../../components/layout/footer";
import Header from "../../../components/layout/header";
import Layout from "../../../components/layout/Layout";
import TemplateOptions from "./forms/TemplateOptions";
import Generator from "../../../engines/Generator";
import useWindowSize from "../../../hooks/UseWindowSize";
import {Template} from "../../../types/template";
import {useHistory} from "react-router-dom";

import TemplateAPI from "../../../firebase/api/template";
import LinkAPI from "../../../firebase/api/link";

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const Body = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
`;

const titleHeight = 50;
const footerHeight = 40;
const initialInputEditorWidth = 450;
const splitterWidth = 1;

const copyToClipboard = (data: string | undefined) => {
  if (!data) return;
  navigator.clipboard.writeText(data).then(() => {
  }).catch(ex => {
    console.error("Failed to copy to clipboard", ex)
  });
};

const downloadFile = (content: string | undefined) => {
  const element = document.createElement("a");
  const file = new Blob([content || ""], {type: 'text/plain'});
  element.href = URL.createObjectURL(file);
  element.download = "myFile.txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const Home = (props: any) => {
  const history = useHistory();

  const [templateId, setTemplateId] = useState<string | null>();
  const [template, setTemplate] = useState<Template | null>(null);

  const [error, setError] = useState<string>();

  // user can change
  const [tokens, setTokens] = useState<string>();
  const [outputContent, setOutputContent] = useState<string>();

  const windowSize = useWindowSize();
  const [width, setWidth] = useState(initialInputEditorWidth);

  const contentWidth = windowSize.width;
  const editorHeight = windowSize.height - titleHeight - footerHeight - 1;

  const generaLinkAndCopyToClipboard = () => {
    if (templateId) {
      LinkAPI.create({
        templateId: templateId,
        tokens: tokens || "{}",
        userId: props.user?.id
      }).then(id => {
        const url = window.location.href;
        const prefix = url.match(/((http|https):\/\/[^/?]*)/);
        if (prefix) {
          const linkUrl = `${prefix[0]}/l/${id}`;
          copyToClipboard(linkUrl);
        }
      }).catch(error => {
        console.log("Failed to register short link", error);
      })
    }
  };

  const resizeCodeEditor = (secondaryPaneSize: number) => {
    setWidth(secondaryPaneSize);
    window.dispatchEvent(new Event('resize'));
  };

  const selectTemplate = (templateId: string) => {
    history.push(`/templates/${templateId}`);
  };

  const onTokensChange = (newValue: string) => {
    setTokens(newValue);
  }

  const resetTemplate = () => {
    setTemplate(null);
    setTokens("");
    setOutputContent("");
  };

  const loadTokensFromLinkId = (lid: string | null) => {
    return new Promise<string | null>((resolve, reject) => {
      // link id not provided
      if (!lid) {
        console.log("No link provided");
        resolve(null);
        return;
      }

      // load link
      console.log("Loading tokens from link", lid);
      LinkAPI.byId(lid).then(link => {
        if (link) {
          resolve(link.tokens)
        } else {
          console.error("Invalid link id, no tokens were loaded");
          resolve(null);
        }
      }).catch(ex => {
        console.error("Failed to load tokens from link", ex);
        reject(ex);
      });
    });
  }

  // template id has been provided, load template
  useEffect(() => {
    const tid = props.match.params.id;
    const lid = props.match.params.linkId;

    // only if template id changes
    if (tid && tid !== templateId) {
      console.log("Loading template...", tid);
      setTemplateId(tid);

      loadTokensFromLinkId(lid).then(linkTokens => {
        TemplateAPI.byId(tid).then(template => {
          if (!template) {
            setError('template not found')
            resetTemplate();
          } else {
            setTemplate(template);
            setTokens(linkTokens || template.sampleTokens);
          }
        }).catch(err => {
          console.error("Failed to load template", err);
          setError('failed to load templates');
          resetTemplate();
        });
      })
    }
  }, [props.match.params.linkId, props.match.params.id, templateId, tokens])

  // Generate content
  useEffect(() => {
    if (template) {
      let parsedTokens: any;

      try {
        parsedTokens = JSON.parse(tokens || "{}");
      } catch (ex) {
        parsedTokens = {};
      }

      Generator.generate(template.engine, template.content || "", parsedTokens).then((result) => {
        setOutputContent(result);
      }).catch(err => {
        console.error("Failed to generate output", err)
        setOutputContent("error");
      });
    }
  }, [tokens, template]);

  return (
    <Layout>
      <Header/>
      <TemplateOptions
        template={template}
        onTemplateSelected={(templateId) => selectTemplate(templateId)}
        onCopyToClipboard={(mode) => (mode === "url") ? generaLinkAndCopyToClipboard() : copyToClipboard(outputContent)}
        onDownloadFile={() => downloadFile(outputContent)}
      />
      <Content>
        <Body>
          <SplitterLayout primaryIndex={0} primaryMinSize={400} percentage={false}
                          secondaryInitialSize={contentWidth - initialInputEditorWidth}
                          onSecondaryPaneSizeChange={resizeCodeEditor}>
            <Editor
              title="Input Tokens"
              mode={template?.inputMode || "json"}
              theme="tomorrow_night"
              width={contentWidth - width - splitterWidth}
              height={editorHeight}
              content={tokens || ""}
              readOnly={false}
              onContentChange={onTokensChange}
            />
            <Editor
              title="Generated Code"
              mode={template?.outputMode || "text"}
              theme="tomorrow_night"
              width={width}
              height={editorHeight}
              content={outputContent || ""}
              disableActiveLineHighlight={true}
              readOnly={true}
            />
          </SplitterLayout>
        </Body>
      </Content>
      <Footer/>
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
