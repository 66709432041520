import {Select, Skeleton} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import {Template} from "../../../../types/template";
import TemplateAPI from "../../../../firebase/api/template";

const {Option} = Select;

const Container = styled.div`
`;

const Label = styled.div`
  margin-bottom: 5px;
  color: #666;
`;

const TemplateSelector = (props: TemplateSelectorProps) => {
  const [templates, setTemplates] = useState<Template[]>();
  const [error, setError] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const onTemplateChange = (value: string) => {
    props.onTemplateSelected(value);
  };

  useEffect(() => {
    if (!isLoaded && props.user) {
      TemplateAPI.byUserId(props.user.id).then(templates => {
        setIsLoaded(true);
        setError(null);
        setTemplates(templates);
      }).catch(error => {
        console.error("Failed to load templates", error);
        setError('Failed to load templates')
      });
    }
  }, [isLoaded, props.user])

  const getSelectedTemplateId = () => {
    if (!props.template) {
      return undefined
    }

    if (props.template?.id === "default") {
      return undefined
    }

    if (props.template?.userId !== props.user.id) {
      return undefined
    }

    return props.template?.id
  }

  return (
    <Container>
      {error && (
        <div style={{color: "red", marginBottom: 10}}>
          {error}
        </div>
      )}

      {isLoaded && (
        <>
          <Label>
            Your templates:
          </Label>
          <Select
            showSearch
            style={{width: "100%"}}
            optionFilterProp="children"
            placeholder="Choose a template"
            onChange={onTemplateChange}
            value={getSelectedTemplateId()}
            defaultValue="Choose a template"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {templates && templates.map((tmp: any) => (
              <Option key={tmp.id} value={tmp.id}>
                <div>
                  {tmp.name}
                </div>
                {tmp.description && (
                  <div style={{color: "#ccc", fontSize: 12}}>
                    {tmp.description}
                  </div>
                )}
              </Option>
            ))}
          </Select>
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelector);

export interface TemplateSelectorProps {
  template: Template | null;
  user: any;
  onTemplateSelected: (templateId: string) => void;
};
