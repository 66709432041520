import React from 'react';
import Editor from "../../../../components/editor/Editor";

const outputLanguageOptions = [
  "text", "html", "kotlin", "ruby", "java", "javascript", "php", "json", "csharp", "objectivec", "golang", "rust", "swift", "typescript", "python", "c_cpp"
].sort();

const OutputEditor = (props: OutputEditorProps) => {
  return (
    <Editor
      key="results_editor"
      title="Result"
      mode={props.mode}
      modeOptions={outputLanguageOptions}
      theme="tomorrow_night"
      width={props.size.width}
      height={props.size.height}
      content={props.content}
      readOnly={true}
      disableActiveLineHighlight={true}
      onModeChange={(newMode) => {
        props.onModeChanged(newMode)
      }}
    />
  );
}

export default OutputEditor;

export interface OutputEditorProps {
  mode: string | null;
  size: { width: number, height: number };
  content: string;
  onModeChanged: (content: string) => void;
}
