import { STORE_ROOT } from './ActionTypes';
import { createStore } from 'redux';
import Reducers from './Reducers';
import StorageManager from './StorageManager';

const store = createStore(
  Reducers,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

store.subscribe(() => {
  // console.log(store.getState());
  StorageManager.save(STORE_ROOT, store.getState());
});

export default store;
