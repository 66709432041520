import React from 'react';
import { connect } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  flex: 0 0 auto;
  width: 240px;
  background-color: #fcfcfc;
  border-right: 1px solid #e5e5e5;
  padding: 10px;
`;

const Index = (props: any) => {
  return (
    <Container>
      {props.children}
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  context: state.context
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
