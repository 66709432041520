import {assert, Context, evalQuotedToken, TagImplOptions, TagToken, Template, Tokenizer, TopLevelToken} from 'liquidjs'

export default {
  parse: function (tagToken: TagToken, remainTokens: TopLevelToken[]) {
    const tokenizer = new Tokenizer(tagToken.args, this.liquid.options.operatorsTrie)
    this.variable = readVariableName(tokenizer)
    assert(this.variable, () => `${tagToken.args} not valid identifier`)

    this.templates = []

    const stream = this.liquid.parser.parseStream(remainTokens)
    stream.on('tag:endjoin', () => stream.stop())
      .on('template', (tpl: Template) => this.templates.push(tpl))
      .on('end', () => {
        throw new Error(`tag ${tagToken.getText()} not closed`)
      })
    stream.start()
  },
  render: function* (ctx: Context) {
    const r = this.liquid.renderer
    const html = yield r.renderTemplates(this.templates, ctx)
    const parts = html.split("\n");
    const length = parts.length;
    const separator = this.variable;
    return parts.map((part: string, index: number) => {
      return (part === "") ? part : `${part}${(index+1 < length) ? separator : ""}`
    }).join("\n");
  }
} as TagImplOptions

function readVariableName(tokenizer: Tokenizer) {
  const word = tokenizer.readIdentifier().content
  if (word) return word
  const quoted = tokenizer.readQuoted()
  if (quoted) return evalQuotedToken(quoted)
}
