import {Modal} from 'antd';
import React from 'react';

const DeleteTemplateModal = (props: DeleteTemplateModalProps) => {
  return (
    <Modal
      title="Delete Template"
      visible={props.visible}
      onOk={() => props.onOk()}
      onCancel={() => props.onCancel()}
    >Are you sure you want to delete this template?</Modal>
  );
}

export default DeleteTemplateModal;

export interface DeleteTemplateModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}
