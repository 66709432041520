import {DownOutlined} from '@ant-design/icons';
import {Button, Dropdown, Menu, Tag} from 'antd';
import React from 'react';
import Gravatar from 'react-awesome-gravatar';
import {connect} from "react-redux";
import styled from "styled-components";
import {logoutUser} from "../../../redux/ActionCreators";
import {useHistory} from "react-router-dom";

const Container = styled.div`
  display: inline-block;
  font-size: 14px;
  padding-top: 0;
`;

const BeltContainer = styled.div`

`;

const Belt = (props: BeltProps) => {

  return (
    <BeltContainer>

    </BeltContainer>
  )
};

interface BeltProps {
  color: string;
}

const getBelt = (belt: string) => {
  switch (belt) {
    case "white":
      return (<Tag color="lightgray">White Belt</Tag>)
    case "yellow":
      return (<Tag color="yellow">Yellow Belt</Tag>)
    case "orange":
      return (<Tag color="orange">Orange Belt</Tag>)
    case "purple":
      return (<Tag color="purple">Purple Belt</Tag>)
    case "blue":
      return (<Tag color="blue">Blue Belt</Tag>)
    case "green":
      return (<Tag color="green">Green Belt</Tag>)
    case "brown":
      return (<Tag color="brown">Brown Belt</Tag>)
    case "black":
      return (<Tag color="black">Black Belt</Tag>)
    default:
      return (<Tag color="lightgray">White Belt</Tag>)
  }
}

const UserMenu = (props: UserMenuProps) => {
  const history = useHistory();

  const user = props.user;

  const menu = (
    <Menu style={{minWidth: 300}}>
      <Menu.Item key="0" style={{
        padding: 0,
        marginTop: -5,
        marginBottom: 3,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderBottom: "1px solid #eee"
      }}>
        <div style={{background: "#efefef", padding: 10}}>
          <div style={{float: "right"}}>
            {getBelt(user.belt || "white")}
          </div>
          <div style={{display: "inline-block"}}>
            <Gravatar email={props.user.email} options={{size: 60}}>
              {url => (
                <img src={url} alt="Mugshot" style={{marginRight: 7, verticalAlign: "middle", borderRadius: 10}}/>)}
            </Gravatar>
          </div>
          <div style={{display: "inline-block"}}>
            <div>
              {user.nickname || user.email.split("@")[0]}
            </div>
            <div style={{color: "#888", fontSize: 12}}>
              {user.email}
            </div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => {
        history.push(`/users/${user.id}/edit`);
      }}>
        Profile Settings
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item key="3" onClick={() => {
        props.onSignOut();
      }}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  if (!user) {
    return (<></>)
  }

  return (
    <Container>
      <Dropdown overlay={menu}>
        <Button onClick={e => e.preventDefault()}>
          <Gravatar email={user.email} options={{size: 20}}>
            {url => (<img src={url} alt="Mugshot" style={{marginRight: 7, verticalAlign: "middle", borderRadius: 3}}/>)}
          </Gravatar>
          <div style={{display: "inline-block", marginRight: 10}}>
            {user.nickname || user.email}
          </div>
          <DownOutlined/>
        </Button>
      </Dropdown>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);

export interface UserMenuProps {
  user: any | null | undefined;
  onSignOut: () => void;
}
