import {Button} from 'antd';
import React from 'react';
import Editor from "../../../../components/editor/Editor";
import {PlayCircleOutlined} from "@ant-design/icons";
import ShortcutLabel from "../../../../components/shortcut";
import {connect} from "react-redux";
import {User} from "../../../../types/template";

const blackBeltOptions = ["liquid", "handlebars", "ejs"];
const whiteBeltOptions = ["liquid"];
const otherBeltOptions = ["liquid", "handlebars"];

const getInputLanguageOptions = (belt: string) => {
  if (belt === "white") {
    return whiteBeltOptions;
  }

  if (belt === "black") {
    return blackBeltOptions
  }

  return otherBeltOptions;
}

const TemplateEditor = (props: TemplateEditorProps) => {

  return (
    <Editor
      key="template_editor"
      title="Template"
      titleContent={(
        <div style={{display: "inline-block", marginRight: 20}}>
          <Button size="small"
                  icon={<PlayCircleOutlined/>}
                  style={{backgroundColor: "lightgreen", color: "black"}}
                  onClick={() => props.onProcessTemplate()}
          >
            Run Template <ShortcutLabel keys="ctrl+r"/>
          </Button>
        </div>
      )}
      mode={props.mode || "liquid"}
      modeOptions={getInputLanguageOptions(props.user?.belt || "white")}
      theme="tomorrow_night"
      width={props.size.width}
      height={props.size.height}
      content={props.content}
      readOnly={false}
      commands={[
        {
          name: "Save template",
          bindKey: {win: "ctrl+s", mac: "command+s"},
          exec: (editor) => props.onSaveContent(editor.getValue())
        }
      ]}
      onModeChange={(newMode) => {
        props.onModeChanged(newMode);
      }}
      onContentChange={(content) => {
        props.onContentChanged(content);
      }}
    />
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(TemplateEditor);

export interface TemplateEditorProps {
  mode: string | null;
  size: { width: number, height: number };
  content: string;
  onModeChanged: (mode: string) => void;
  onContentChanged: (content: string) => void;
  onSaveContent: (content: string) => void;
  onProcessTemplate: () => void;
  user?: User | null | undefined;
}
