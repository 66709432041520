export default {
  apiKey: "AIzaSyCIje_CbIp0RE-t9n92fL3BRPDvc8NM0Uc",
  authDomain: "codegen-6f642.firebaseapp.com",
  databaseURL: "https://codegen-6f642-default-rtdb.firebaseio.com",
  projectId: "codegen-6f642",
  storageBucket: "codegen-6f642.appspot.com",
  messagingSenderId: "1083460231576",
  appId: "1:1083460231576:web:fa8b7e5f5a89cf9f420bed",
  measurementId: "G-PXWVTB61P4"
};
