import {firestore} from "../index";
import {TemplateLink} from "../../types/template";
import {nanoid} from "nanoid";

const collectionName = "links";

const getCollection = () => {
  return firestore.collection(collectionName);
}

const LinkAPI = {
  byId: (linkId: string) => {
    return new Promise<TemplateLink | null>((resolve, reject) => {
      getCollection().doc(linkId).get()
        .then(template => {
          const data = template.exists ? template.data() : null;
          if (data) {
            resolve({
              id: linkId,
              templateId: data.templateId,
              tokens: data.tokens
            })
          } else {
            resolve(null)
          }
        })
        .catch(e => {
          reject(e)
        });
    })
  },

  create: (params: LinkCreateRequest) => {
    const id = nanoid();
    console.log("Generating link...", id);

    return new Promise<string>((resolve, reject) => {
      getCollection().doc(id).set(
        {
          id: id,
          templateId: params.templateId,
          tokens: params.tokens || null,
          userId: params.userId || null,
          createdAt: new Date(),
          lastAccessedAt: new Date(),
          accessCount: 0
        }
      ).then(() => {
        resolve(id)
      }).catch(error => {
        reject(error);
      });
    });
  }
}

export default LinkAPI;

export interface LinkCreateRequest {
  templateId: string;
  tokens: string;
  userId: string | null | undefined;
}
