import {Liquid} from 'liquidjs';
import joinTag from "./tags/join";
import {toCamelCase, toKebabCase, toUnderscore, toVariableName} from "./filters/naming";
import {toKotlinType} from "./filters/types";

const engine = new Liquid({
  cache: true
});

engine.registerFilter('underscore', v => {
  return toUnderscore(v);
});

engine.registerFilter('snakecase', v => {
  return toUnderscore(v);
});

engine.registerFilter('kebabcase', v => {
  return toKebabCase(v);
});

engine.registerFilter('camelcase', v => {
  return toCamelCase(v);
});

engine.registerFilter('camelvar', v => {
  return toVariableName(v);
});

engine.registerFilter('toKotlinType', v => {
  return toKotlinType(v);
});

// Usage: {%- join "," %} {%- endjoin %}
engine.registerTag('join', joinTag);

const LiquidEngine = {
  generate: (template, tokens) => {
    return new Promise((resolve, reject) => {
      try {
        engine.parseAndRender(template, tokens).then((content) => {
          resolve(content);
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
};

export default LiquidEngine;
