import {Skeleton} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import styled from "styled-components";
import Header from "../../../components/layout/header";
import Footer from "../../../components/layout/footer";
import Layout from "../../../components/layout/Layout";
import Navigation from "../../../components/layout/navbar";
import useWindowSize from "../../../hooks/UseWindowSize";
import TemplateForm from "./forms/TemplateForm";
import Generator from "../../../engines/Generator";
import {Template} from "../../../types/template";
import {connect} from "react-redux";
import {Prompt} from 'react-router';
// @ts-ignore
import {useBeforeunload} from 'react-beforeunload';
import InputEditor from "./editors/InputEditor";
import OutputEditor from "./editors/OutputEditor";
import TemplateEditor from "./editors/TemplateEditor";
import DeleteTemplateModal from "./modals/DeleteTemplateModal";
import SaveAsNewTemplateModal from "./modals/SaveAsNewTemplateModal";
import TemplateAPI from "../../../firebase/api/template";

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const Body = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
`;

const titleHeight = 50;
const footerHeight = 40;
const splitterWidth = 1;
const navigationWidth = 240;
const initialTopEditorHeight = 400;

const toKeywords = (value: string) => {
  return value.split(" ").map(val => val.toLowerCase()).filter(val => val !== "")
};

const uniq = (a: string[]) => {
  return a.sort().filter(function (item, pos, ary) {
    return !pos || item !== ary[pos - 1];
  }).filter((item: string) => (item.length > 3));
}

const processTemplate = (tokens: string, mode: string | undefined | null, content: string | undefined | null) => {
  return new Promise<string>((resolve, reject) => {
    if (!mode) {
      console.error("Template mode not provided");
      reject("Unable to process template, mode not specified");
      return;
    }

    if (!content) {
      console.error("Template content not provided");
      reject("Unable to process template, content not provided");
      return;
    }

    let parsedTokens;

    try {
      parsedTokens = JSON.parse(tokens);
    } catch (error) {
      console.error("Unable to process tokens", error);
      reject("Unable to process tokens");
      return;
    }

    Generator.generate(mode, content, parsedTokens).then((result) => {
      resolve(result);
    }).catch(error => {
      console.error("Unable to process template", error);
      reject("Unable to process template");
    });
  });
}

const TemplatePage = (props: any) => {
  const history = useHistory();

  const templateId = props.match.params.id;

  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState<boolean>(false);
  const [template, setTemplate] = useState<Template | null>();

  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [error, setError] = useState<string | null>();
  const [inputMode, setInputMode] = useState<string | null>();

  const [contentMode, setContentMode] = useState<string | null>();
  const [outputMode, setOutputMode] = useState<string | null>();
  const [content, setContent] = useState<string | null>();

  const [tokens, setTokens] = useState<string | null>();
  const [outputContent, setOutputContent] = useState<string | null>("");

  const windowSize = useWindowSize();
  const contentWidth = windowSize.width - navigationWidth;
  const contentHeight = windowSize.height - titleHeight - footerHeight;
  const initialLeftEditorWidth = contentWidth / 2;

  const [rightEditorWidth, setRightEditorWidth] = useState(initialLeftEditorWidth);
  const [bottomEditorHeight, setBottomEditorHeight] = useState(initialTopEditorHeight);
  const leftEditorHeight = contentHeight - 1;

  const [isCloneModalVisible, setCloneModalVisible] = useState<boolean>(false);

  useBeforeunload((event: any) => {
    if (isDirty) {
      event.preventDefault()
    }
  });

  const resizeVerticalSplitter = (secondaryPaneSize: number) => {
    setRightEditorWidth(secondaryPaneSize);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  };

  const resizeHorizontalSplitter = (secondaryPaneSize: number) => {
    setBottomEditorHeight(secondaryPaneSize);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  };

  const resetTemplate = () => {
    setTemplate(null);
    setContent("");
    setTokens("");
    setInputMode("");
    setContentMode("");
    setOutputMode("");
  };

  const saveTemplate = (name: string, description: string, tags: string[], isPrivate: boolean) => {
    const templateId = props.match.params.id;
    const searchMetadata = tags.map(tag => tag.toLowerCase());
    toKeywords(name).forEach(val => searchMetadata.push(val));
    toKeywords(description).forEach(val => searchMetadata.push(val));

    TemplateAPI.update({
      id: templateId,
      name: name,
      description: description,
      engine: contentMode,
      input: inputMode,
      output: outputMode,
      sampleTokens: tokens,
      content: content,
      isPrivate: isPrivate,
      tags: tags,
      searchMetadata: uniq(searchMetadata)
    }).then(() => {
      setIsDirty(false);
    }).catch(error => {
      console.error("Failed to save template", error);
    });
  }

  const saveContent = (content: string) => {
    TemplateAPI.update({
      id: templateId,
      content: content
    }).then(() => {
      console.log("Content saved");
      // there is a bug in Ace - so this does not clear all dirty flags
    }).catch(error => {
      console.error("Failed to save content", error);
    });
  }

  const saveTokens = (sampleTokens: string) => {
    TemplateAPI.update({
      id: templateId,
      sampleTokens: sampleTokens
    }).then(() => {
      console.log("Tokens saved");
      // there is a bug in Ace - so this does not clear all dirty flags
    }).catch(error => {
      console.error("Failed to save tokens", error);
    });
  }

  const prepareAndProcessTemplate = () => {
    processTemplate(tokens || "{}", contentMode, content).then(output => {
      setOutputContent(output);
    }).catch(error => {
      setOutputContent(error);
    });
  }

  const onCancelEdit = () => {
    history.push(`/templates/${templateId}`);
  };

  const cloneTemplate = (newName: string) => {
    TemplateAPI.create({
      name: newName,
      description: template?.description || "",
      engine: template?.engine || "",
      input: template?.inputMode || "json",
      output: template?.outputMode || "text",
      sampleTokens: template?.sampleTokens || "{}",
      content: template?.content || "{}",
      isPrivate: template?.isPrivate,
      userId: props.user.id,
      tags: template?.tags || []
    }).then(() => {
      setCloneModalVisible(false);
      resetTemplate();
      history.push(`/templates/${templateId}/edit`);
    }).catch(error => {
      console.log(error);
      setError('failed to clone a template')
    });
  };

  const deleteTemplate = () => {
    if (!templateId) return;

    setDeleteConfirmationVisible(false);

    TemplateAPI.delete(templateId).then(() => {
      resetTemplate();

      setTimeout(() => {
        history.push(`/`);
      }, 500);
    }).catch(error => {
      console.log(error);
      setError('failed to delete a template')
    });
  };


  useEffect(() => {
    if (!props.user) {
      console.log("User not provided, not doing anything");
      return;
    }

    if (template?.id !== templateId) {
      TemplateAPI.byId(templateId).then(temp => {
        if (temp) {
          if (props.user && temp.userId !== props.user.id) {
            setTimeout(() => {
              history.push(`/templates/${templateId}`);
            }, 300);
            return
          }

          setTemplate(temp);
          setContent(temp.content);
          setTokens(temp.sampleTokens);
          setInputMode(temp.inputMode);
          setContentMode(temp.engine);
          setOutputMode(temp.outputMode);
        } else {
          setError('template not found')
          resetTemplate();
        }
      }).catch(error => {
        console.log("Failed to load template", error);
        setError('failed to load templates')
        resetTemplate();
      });
    }
  }, [history, props.user, template, templateId])

  return (
    <Layout>
      <Header/>
      <Content>
        <Prompt
          when={isDirty}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Navigation>
          {template && (<TemplateForm
            template={template}
            onSaveTemplate={saveTemplate}
            onCancel={onCancelEdit}
            onCloneTemplate={() => setCloneModalVisible(true)}
            onDeleteTemplate={() => setDeleteConfirmationVisible(true)}
          />)
          }
          {!template && (<Skeleton active/>)}
        </Navigation>
        <Body>
          {template && (
            <SplitterLayout
              primaryIndex={0}
              primaryMinSize={400}
              percentage={false}
              secondaryInitialSize={contentWidth - initialLeftEditorWidth}
              onSecondaryPaneSizeChange={resizeVerticalSplitter}
            >
              <TemplateEditor
                mode={contentMode || "liquid"}
                size={{width: contentWidth - rightEditorWidth - splitterWidth, height: leftEditorHeight}}
                content={content || ""}
                onContentChanged={(content: string) => {
                  setIsDirty(true);
                  setContent(content);
                }}
                onModeChanged={(newMode) => {
                  setIsDirty(true);
                  setContentMode(newMode);
                }}
                onSaveContent={(content: string) => {
                  saveContent(content)
                }}
                onProcessTemplate={() => {
                  prepareAndProcessTemplate()
                }}
              />

              <SplitterLayout
                vertical
                primaryIndex={0}
                primaryMinSize={200}
                percentage={false}
                secondaryInitialSize={contentHeight - initialTopEditorHeight}
                onSecondaryPaneSizeChange={resizeHorizontalSplitter}
              >
                <InputEditor
                  mode={inputMode || "json"}
                  size={{width: rightEditorWidth, height: contentHeight - bottomEditorHeight - 6}}
                  content={tokens || ""}
                  onContentChanged={(content: string) => {
                    setIsDirty(true);
                    setTokens(content);
                  }}
                  onSaveContent={(content: string) => {
                    saveTokens(content)
                  }}
                />
                <OutputEditor
                  mode={outputMode || "text"}
                  size={{width: rightEditorWidth, height: bottomEditorHeight}}
                  content={outputContent || ""}
                  onModeChanged={(newMode) => {
                    setIsDirty(true);
                    setOutputMode(newMode);
                  }}
                />
              </SplitterLayout>
            </SplitterLayout>
          )}
          {!template && (
            <div style={{margin: 10}}>
              <Skeleton paragraph={{rows: 6}} active/>
            </div>
          )}

          <DeleteTemplateModal
            visible={isDeleteConfirmationVisible}
            onOk={() => deleteTemplate()}
            onCancel={() => setDeleteConfirmationVisible(false)}
          />

          <SaveAsNewTemplateModal
            visible={isCloneModalVisible}
            name={template?.name || ""}
            onOk={(newName) => cloneTemplate(newName)}
            onCancel={() => setCloneModalVisible(false)}
          />
        </Body>
      </Content>
      <Footer/>
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
