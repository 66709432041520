export const toCamelCase = (value: string) => {
  if (!value) return "";
  const val = value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  return val.substring(0, 1).toUpperCase() + val.substring(1);
};

export const toUnderscore = (value: string) => {
  if (!value) return "";
  return value.replace(/\.?([A-Z])/g, function (x, y) {
    return "_" + y.toLowerCase()
  }).replace(/^_/, "");
};

export const toKebabCase = (value: string) => {
  if (!value) return "";
  return value.replace(/\.?([A-Z])/g, function (x, y) {
    return "-" + y.toLowerCase()
  }).replace(/^-/, "");
};

export const toVariableName = (value: string) => {
  if (!value) return "";
  const val = toCamelCase(value);
  return val.substring(0, 1).toLowerCase() + val.substring(1);
};
