import {firestore} from "../index";
import {Template} from "../../types/template";
import {v4 as uuidv4} from "uuid";

const collectionName = "templates";

const getCollection = () => {
  return firestore.collection(collectionName);
}

const removeUndefined = (obj: any) => {
  for (let propName in obj) {
    if (obj.hasOwnProperty(propName) && obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

const TemplateAPI = {
  byId: (templateId: string) => {
    return new Promise<Template | null>((resolve, reject) => {
      getCollection().doc(templateId).get()
        .then(template => {
          const data = template.exists ? template.data() : null;
          if (data) {
            resolve({
              id: templateId,
              name: data.name,
              description: data.description,
              content: data.content,
              engine: data.engine,
              inputMode: data.input,
              outputMode: data.output,
              isPrivate: data.isPrivate,
              userId: data.userId,
              sampleTokens: data.sampleTokens,
              tags: data.tags
            })
          } else {
            resolve(null)
          }
        })
        .catch(e => {
          reject(e)
        });
    })
  },

  byUserId: (userId: string, limit?: number) => {
    return new Promise<Template[]>((resolve, reject) => {

      getCollection().where('userId', '==', userId).limit(limit || 50).get().then(templateList => {
        const templates: Template[] = [];

        templateList.forEach(doc => {
          const data = doc.data();
          templates.push({
            id: doc.id,
            name: data.name,
            description: data.description,
            content: data.content,
            engine: data.engine,
            inputMode: data.input,
            outputMode: data.output,
            isPrivate: data.isPrivate,
            userId: data.userId,
            sampleTokens: data.sampleTokens,
            tags: data.tags
          });
        });

        resolve(templates);
      }).catch(error => {
        reject(error);
      });

    });
  },

  create: (params: TemplateCreateRequest) => {
    const templateId = uuidv4();

    return new Promise((resolve, reject) => {
      getCollection().doc(templateId).set(removeUndefined(params)).then(() => {
        resolve(true)
      }).catch(error => {
        reject(error);
      });
    });
  },

  update: (params: TemplateUpdateRequest) => {
    return new Promise((resolve, reject) => {
      getCollection().doc(params.id).update(removeUndefined(params)).then(() => {
        resolve(true)
      }).catch(error => {
        reject(error);
      });
    });
  },

  delete: (templateId: string) => {
    return new Promise((resolve, reject) => {
      getCollection().doc(templateId).delete().then(() => {
        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

export default TemplateAPI;

export interface TemplateCreateRequest {
  name?: string;
  description?: string;
  engine?: string;
  input?: string;
  output?: string;
  sampleTokens?: any;
  content?: string;
  isPrivate?: boolean;
  userId?: string;
  tags?: string[];
}

export interface TemplateUpdateRequest {
  id: string;
  name?: string | null;
  description?: string | null;
  engine?: string | null;
  input?: string | null;
  output?: string | null;
  sampleTokens?: any | null;
  content?: string | null;
  isPrivate?: boolean | null;
  userId?: string | null;
  tags?: string[] | null;
  searchMetadata?: string[] | null;
}
